import { Component, OnInit } from '@angular/core';
import { ExecuteQueryService } from 'src/app/shared/services/execute-query/execute-query.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(
    private executeQuerySvc: ExecuteQueryService
  ) { }

  ngOnInit(): void {
    this.executeQuerySvc.executeQuery('select * from ClientContact').subscribe(res => {
      console.log("Query Response", res);
    })
  }
}
