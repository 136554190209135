<app-spinner></app-spinner>
<div class="row-no-gutters main-wrapper">
  <div class="col-md-6 col-sm-12 login-wrapper">
    <div class="login-inner-wrapper">
      <img class="logo" alt="" src={{logo}}>

      <mat-card *ngIf="!forgotPassword">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="row-no-gutters">
            <div class="col-xs-12">
              <mat-form-field>
                <mat-label>Username</mat-label>
                <input matInput type="text" placeholder="username" formControlName="username">
              </mat-form-field>
            </div>

            <div class="col-xs-12">
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput type="password" placeholder="password" formControlName="password">
              </mat-form-field>
            </div>

            <div class="col-xs-12" [ngClass]="{'mb-0': !errorMessage}">
              <button color="primary" type="submit" name="submit" mat-raised-button [disabled]="loginForm.invalid">Sign
                In</button>
            </div>

            <div class="col-xs-12 mb-0 error-message" *ngIf="errorMessage">
              <mat-icon>warning</mat-icon>{{errorMessage}}
            </div>
          </div>
        </form>
      </mat-card>

      <app-reset-password *ngIf="forgotPassword" (showLogin)="showLogin($event)"></app-reset-password>

      <div id="forget-password">
        <a (click)="forgotPassword = !forgotPassword">
          <span *ngIf="!forgotPassword">Forgot Password</span>
          <span *ngIf="forgotPassword"> Back To Login</span>
        </a>
      </div>

      <footer class="footer-links">
        <a target="_blank" href="https://360intellect.com">Home</a>
        <a target="_blank" href="https://360intellect.com/contact-us">Contact Us</a>
        <!-- <p>© {{getYear}} {{getCopyRights()}}</p> -->
      </footer>
    </div>
  </div>

  <div class="col-xs-6 image-wrapper hidden-xs hidden-sm">
    <img src="{{selecteImage}}" />
    <div class="datacontainer" #dataContainer></div>
  </div>
</div>