import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/core';
import { ISession, Session } from 'src/app/shared/model/session.model';
import { IUser, User } from 'src/app/shared/model/user.model';
import { IWebConfig } from 'src/app/shared/model/web-config.model';
import { LoggedinUserService } from 'src/app/shared/services/loggedin-user/loggedin-user.service';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { environment } from 'src/environments/environment';
import { DialogBoxComponent, DialogData } from 'src/app/shared/components/dialog-box/dialog-box.component';
import { forkJoin, Observable } from 'rxjs';
import { UserSecurityService } from 'src/app/core/services/userSecurity/user-security.service';

interface IClientIdByURL {
  clientId: string;
}

interface ILogin {
  user: IUser;
  session: ISession;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  // @ViewChild('dataContainer') dataContainer: ElementRef;
  @ViewChild('dataContainer', { static: false }) dataContainer!: ElementRef;

  loginForm: FormGroup;
  bgImagesArray: any = [];
  selecteImage = '';
  intervalFunction: any;
  imagaePath = '';
  imgText = '';
  imgPos = '';
  getYear = new Date().getFullYear();

  errorMessage = '';
  clientId = '';
  forgotPassword: boolean = false;
  questions: any[] = [];
  logo: string;

  constructor(
    private formBuilder: FormBuilder,
    private loginSvc: LoginService,
    public dialog: MatDialog,
    private loggedInUserSvc: LoggedinUserService,
    private sessionSvc: SessionService,
    private userSecuritySvc: UserSecurityService,
    @Inject(DOCUMENT) private document: Document,
  ) { }
  ngOnInit(): void {
    // set the default styles
    this.setStyles();

    // get client id
    this.getClientIdByURL();
    // this.getImages();

    // initiate form data
    this.initForm();

    setTimeout(() => {
      this.getRandomImage();
    });

    this.intervalFunction = setInterval(() => {
      this.getRandomImage();
    }, 60000);
  }

  getClientIdByURL() {
    this.loginSvc.getClientIdByURL().subscribe((res: IClientIdByURL) => {
      this.clientId = res.clientId;
      console.log(this.clientId);

      this.getWebConfig();
    });
  }

  getWebConfig() {
    this.loginSvc.getWebConfig(this.clientId).subscribe((res: IWebConfig[]) => {
      this.setStyles(res);
    });
  }

  setStyles(data?: IWebConfig[]) {
    this.loggedInUserSvc.setWebConfig(data || []);
    const config = this.loggedInUserSvc.webConfig.getWebConfigObj();
    this.setImage();
    console.log(config);

    Object.entries(config).map(([key, value]) => {
      this.document.documentElement.style.setProperty(`--${key}`, value);
    });
  }

  setImage() {
    this.logo = this.loggedInUserSvc.webConfig.logo;
    this.bgImagesArray = [{
      imgName: this.loggedInUserSvc.webConfig.loginSplashImage1,
      imgText: "",
      imgPos: "",
    },
    {
      imgName: this.loggedInUserSvc.webConfig.loginSplashImage2,
      imgText: "",
      imgPos: "",
    }
    ];
  }
  initForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onSubmit() {
    const data = { ...this.loginForm.value, clientId: this.clientId };
    this.loginSvc.login(data).subscribe(
      (res: ILogin) => {
        console.log(res);
        this.loggedInUserSvc.user = new User(res.user);
        this.getUserSecurityQuestions(res.session);
      },
      (err: any) => {
        console.log(err);
        this.errorMessage = err.errorMessage;
        this.loggedInUserSvc.logout();
      }
    );

    // this.getUserSecurityQuestions();
    // const questions = await this.getUserSecurityQuestions();
    // if (questions.length > 0) {
    //   this.sessionSvc.session = new Session(response.session);
    //   this.loggedInUserSvc.navigateUserAccordingToUserRole();
    // } else {
    //   this.getSecurityQuestions(response);
    // }
  }

  // loadLoginImages() {
  //   this.loginSvc.getLoginpageImages().subscribe(
  //     (response) => {
  //       this.bgImagesArray = this.readImagenames(response['records']);
  //       this.getRandomImage();
  //     },
  //     (error) => { }
  //   );
  // }

  // getImages() {
  //   this.imagaePath = environment.IMAGE_PATH;
  //   this.loadLoginImages();
  //   this.intervalFunction = setInterval(() => {
  //     this.getRandomImage();
  //   }, 60000);
  // }

  readImagenames(imgDetailsArray) {
    let imageArray = [];
    imgDetailsArray.forEach((imgObject) => {
      imageArray.push({
        imgName: imgObject.Name + '.jpg',
        imgText: imgObject.Image_Text_1__c + imgObject.Image_Text_2__c,
        imgPos: imgObject.Position__c,
      });
    });
    return imageArray;
  }

  getRandomImage() {
    const ImgObj =
      this.bgImagesArray[Math.floor(Math.random() * this.bgImagesArray.length)];
    this.selecteImage = ImgObj.imgName;
    this.imgText = ImgObj.imgText;
    this.imgPos = ImgObj.imgPos;
    this.dataContainer.nativeElement.innerHTML = this.imgText;
    this.dataContainer.nativeElement.style = this.imgPos;
  }

  ngOnDestroy() {
    clearInterval(this.intervalFunction);
  }

  showLogin(event) {
    this.forgotPassword = false;
  }

  getSecurityQuestions(session) {

    this.userSecuritySvc.getSecurityQuestions().subscribe((res: any) => {
      this.questions = res ? Object.assign([], res) : [];
      this.openSecurityQuestionsDialog().subscribe((res: any) => {
        // console.log(res);
        if (res) {
          forkJoin([
            this.userSecuritySvc.saveUserSecurityQuestions(res.question1, res.answer1),
            this.userSecuritySvc.saveUserSecurityQuestions(res.question2, res.answer2),
            this.userSecuritySvc.saveUserSecurityQuestions(res.question3, res.answer3),
          ]).subscribe({
            next: ([res1, res2, res3]: any[]) => {
              if (res1 && res2 && res3) {
                // console.log("Saved Security Questions successfully");
                this.sessionSvc.session = new Session(session);
                this.loggedInUserSvc.navigateUserAccordingToUserRole();
              }
            }
          });
        } else {
          this.errorMessage = "Please Fill Security Questions First!";
          this.loggedInUserSvc.logout();
        }
      });
      // console.log("Security Questions ------------->", res);
    });
  }


  getUserSecurityQuestions(session) {
    this.userSecuritySvc.getUserSecurityQuestions().subscribe(
      (res: any) => {
        // console.log("Security Questions ------------->", res);
        // res = [];
        if (res && res.length === 0) {
          this.getSecurityQuestions(session)
        } else {
          this.sessionSvc.session = new Session(session);
          this.loggedInUserSvc.navigateUserAccordingToUserRole();
        }
      });
  }

  openSecurityQuestionsDialog(): Observable<any> {
    const dialogData: any = {
      title: 'Setup Security Questions',
      data: this.questions.map((question) => ({
        label: question.SecurityQuestion,
        value: question.SecurityQuestionID
      })),
    };

    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '600px',
      height: 'auto',
      data: dialogData,
    });

    return dialogRef.afterClosed();
  }

}
